<template lang="html">
  <header class="TableHeader">
    <div class="TableColumn TableColumn--check" v-if="checked">
      <Checkbox
      name="all"
      :value="allChecked"
      :checkboxValue="allChecked"
      @change="markAll"></Checkbox>
    </div>

    <TableHeaderItem
    v-for="item in columns"
    :key="item.labels"
    :col="item"
    :activeBase="orderedBy"
    @sortChanged="orderBy" />

  </header>
</template>

<script>
import TableHeaderItem from '@/partials/Table/TableHeaderItem'
import Checkbox from '@/partials/Forms/Checkbox';
export default {
  name: 'TableHeader',
  props: ['orderedBy', 'checked',  'allChecked', 'columns'],
  components: {
    Checkbox,
    TableHeaderItem,
  },
  methods: {
    orderBy(payload) {
      this.$emit('sortChanged', payload)
    },
    markAll(payload) {
      this.$emit('markAll', payload)
    }
  }
}
</script>
