const PaginationHelper = {
  splitList(list, listQuery) {
    const result = []
    var i, j, temparray, chunk = listQuery.limit;
    for (i = 0, j = list.length; i < j; i += chunk) {
      temparray = list.slice(i, i + chunk);
      result.push(temparray)
    }
    return result
  }
}


export default PaginationHelper
