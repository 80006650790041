<template lang="html">
  <label :class="['Checkbox', isCheckboxActive ? 'Checkbox--active' : '']">
    <input type="checkbox" :value="value" @change="handleChange" class="Checkbox__input">
    <div class="Checkbox__control">
      <svg-icon icon="checked"></svg-icon>
    </div>
    <span class="Checkbox__label" v-if="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  model: {
    prop: 'checkboxValue',
    event: 'change',
  },
  props: {
    checkboxValue: {
      type: [String, Array, Number, Boolean],
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    }
  },
  methods: {
    handleChange(event) {
      let isChecked = event.target.checked

      if (this.checkboxValue instanceof Array) {
        let newValue = [...this.checkboxValue]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }

        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? true : false)
      }
    }
  },
  computed: {
    isCheckboxActive() {
      if (this.checkboxValue instanceof Array) {
        return this.checkboxValue.includes(this.value)
      }
      return this.checkboxValue === true
    }
  }
}
</script>
