import PaginationHelper from '@/helpers/PaginationHelper'
import TableHeader from '@/partials/Table/TableHeader'
import Pagination from '@/partials/Pagination'

import Helper from '@/helpers/SiteHelper'

let baseUrl = process.env.VUE_APP_FRONT_URL
  ? process.env.VUE_APP_FRONT_URL
  : 'https://selectrapp.com/'

const TableMixin = {
  components: {
    TableHeader,
    Pagination,
  },
  props: {
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
    },
    searchQuery: {
      type: String,
      required: false,
    },
    paginationHistory: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: Object,
    },
  },
  data() {
    return {
      url: baseUrl,
      list: this.items,
      currentSort: null,
      chunkedList: [],
      orderedBy: null,
      allChecked: false,
      selectedItems: [],
      total: this.items.length,
      listQuery: {
        page: 1,
        limit: 10,
      },
      searchOptions: {
        threshold: 0.4,
        keys: ['name', 'email', 'birthday'],
      },
    }
  },
  mounted() {
    this.chunkList(this.list)
  },
  methods: {
    chunkList(list) {
      this.chunkedList = PaginationHelper.splitList(list, this.listQuery)
      this.listQuery.page = 1
    },

    markAll(payload) {
      let checkedElements = []
      if (payload) {
        this.chunkedList[this.listQuery.page - 1].forEach((item) => {
          checkedElements.push(item.id)
        })
        this.selectedItems = checkedElements
      } else {
        this.selectedItems = []
      }
    },

    handlePagination(payload) {
      this.listQuery.page = payload.page
    },

    resetOrder() {
      this.currentSort = null
      this.orderedBy = null
      this.chunkList(this.list)
    },

    orderBy(payload) {
      if (payload.status == 0) {
        this.resetOrder()
      } else {
        this.currentSort = payload
        let prop = payload.sortedBy
        this.orderedBy = prop
        this.chunkList(
          this.list.concat().sort((a, b) => {
            let varA = Helper.resolve(prop, a)
            let varB = Helper.resolve(prop, b)
            let comparison = 0
            if (varA == null) {
              comparison = -1
            } else if (varB == null) {
              comparison = 1
            } else if (varA > varB) {
              comparison = 1
            } else if (varA < varB) {
              comparison = -1
            }
            if (payload.status == 1) {
              return comparison
            } else {
              return comparison * -1
            }
          }),
        )
      }
    },

    handleSearch(term) {
      if (term) {
        this.$search(term, this.items, this.searchOptions).then((results) => {
          this.list = results
          this.afterSearch()
        })
      } else {
        this.list = this.items
        this.afterSearch()
      }
    },

    afterSearch() {
      this.total = this.list.length
      if (this.currentSort) {
        this.orderBy(this.currentSort)
      } else {
        this.resetOrder()
      }
    },
  },

  watch: {
    selectedItems(nextState) {
      this.$emit('selection', nextState)
      this.allChecked = nextState.length == this.listQuery.limit
    },
    searchQuery(val) {
      if (val.length > 2) {
        this.handleSearch(val)
      } else if (val.length == 0) {
        this.handleSearch(false)
      }
    },
    sort: {
      deep: true,
      handler: function(next) {
        this.orderBy(next)
      },
    },
  },
}

export default TableMixin
