<template lang="html">
  <div class="Pagination" v-show="total > limit">
    <div
      class="Pagination__arrow Pagination__arrow--prev"
      @click="handleArrowClick(false)"
    >
      <svg-icon icon="chevron-left"></svg-icon>
      <span>Prev</span>
    </div>
    <div class="Pagination__main Pagination__main--desktop">
      <div class="Pagination__page" v-for="i in pages" :key="i">
        <div
          :class="[
            'Pagination__item',
            activePage == i ? 'Pagination__item--current' : '',
          ]"
          @click="moveToPage(i)"
        >
          {{ i }}
        </div>
      </div>
    </div>
    <div class="Pagination__main Pagination__main--mobile">
      <div class="Pagination__page">
        <div class="Pagination__item Pagination__item--single">
          {{ activePage }} / {{ pages }}
        </div>
      </div>
    </div>
    <div
      class="Pagination__arrow Pagination__arrow--next"
      @click="handleArrowClick(true)"
    >
      <span>Next</span>
      <svg-icon icon="chevron-right"></svg-icon>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    props: {
      total: {
        required: true,
        type: Number,
      },
      page: {
        type: Number,
        default: 1,
      },
      limit: {
        type: Number,
        default: 10,
      },
      paginationHistory: {
        type: Boolean,
        default: false,
      },
    },
    data: function() {
      return {
        activePage: 1,
        pages: 0,
      }
    },
    mounted() {
      this.preparePages()
      if (this.paginationHistory) {
        this.setPage()
      }
    },
    methods: {
      preparePages() {
        this.pages = Math.ceil(this.total / this.limit)
      },
      setPage() {
        let currentPage = this.$route.query.page ? this.$route.query.page : 1
        this.activePage = currentPage
        this.handleCurrentChange(currentPage)
      },
      moveToPage(pageNum) {
        this.activePage = pageNum
        this.handleCurrentChange(this.activePage)
      },
      handleArrowClick(val) {
        if (val) {
          this.activePage =
            this.activePage >= this.pages
              ? this.activePage
              : this.activePage + 1
        } else {
          this.activePage = this.activePage == 1 ? 1 : this.activePage - 1
        }
        this.handleCurrentChange(this.activePage)
      },
      handleCurrentChange(val) {
        this.$emit('pagination', { page: val })
        if (this.paginationHistory) {
          this.$router.replace({ query: { page: val } })
        }
      },
    },
    watch: {
      total() {
        this.preparePages()
      },
      limit() {
        this.preparePages()
      },
      page(val) {
        this.moveToPage(val)
      },
    },
  }
</script>

<style lang="css" scoped></style>
